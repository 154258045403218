import axios from 'axios'

const pwd = {
  // * 忘記密碼 API
  async ForgetPwd (postData) {
    const url = `${process.env.VUE_APP_API}/api/members/ForgetSecretCode`
    const res = await axios.post(url, postData)
    if (res.data.rtnCode !== 0) {
      return {
        isSuccess: false,
        message: res.data.rtnMsg,
        rtnCode: res.data.rtnCode
      }
    }
    return {
      isSuccess: true
    }
  },
  // * 修改密碼 API (忘記密碼導入的頁面)
  async ChangePwd (postData2) {
    const url = `${process.env.VUE_APP_API}/api/members/ForgetSecret_Change`
    const res = await axios.post(url, postData2)
    if (res.data.rtnCode !== 0) {
      return {
        isSuccess: false,
        message: res.data.rtnMsg,
        rtnCode: res.data.rtnCode
      }
    }
    return {
      isSuccess: true
    }
  },
  //* 個人資料密碼修改 API (會員中心頁面)
  async ChangePwdInfo (updatePwd) {
    const url = `${process.env.VUE_APP_API}/api/members/ChangeSecretCode`
    const res = await axios.post(url, updatePwd)
    if (res.data.rtnCode === 0) {
      return {
        isSuccess: false,
        message: res.data.rtnMsg,
        rtnCode: res.data.rtnCode
      }
    }
    return {
      isSuccess: true
    }
  }
}

export default pwd
